import { styled } from '@linaria/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { dlEventSend } from 'gatsby-plugin-purina-analytics/common/functions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { CMS_THEME } from 'src/common/enums';
import { defaultTheme } from '../../theme/defaultTheme';
import { theme } from '../../theme/theme';
import { ButtonLink } from '../button/Button';
import RichText from '../rich_text/RichText';

interface Props {
  image: IGatsbyImageData;
  link: string;
  target?: string;
  topic: string;
  title: string;
  buttonText: string;
  event_speakers?: string;
  body: string;
  duration: string;
  height?: string;
  gtmIsTrack?: boolean;
  location?: string;
  contentType?: string;
}

const StyledImage = styled.div`
  background-color: ${defaultTheme.colors.border};
  box-sizing: border-box;

  // forcing opacity on img to prevent placeholder issue on paginated results PI-211
  img {
    opacity: 1 !important;
  }
`;

const StyledParagraphTruncate = styled.div`
  padding-top: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  & > a {
    display: block;
    margin-right: auto;
  }
`;

const CardTitleLink = styled.p`
  color: ${theme.light.text.default};
`;

const CardTopic = styled.p`
  color: ${theme.light.text.default};
  font-size: 1.125rem;
`;

const StyledCardContainer = styled.div`
  a {
    hyphens: auto;
    overflow-wrap: break-word;
    word-break: break-word;
    text-transform: uppercase;
    @media (min-width: ${theme.media.lg}) {
      font-size: 1rem;
    }
    @media (min-width: ${theme.media.xl}) {
      font-size: 1.125rem;
    }
  }
`;

const StyledCard: React.FC<Props> = function ({
  title,
  link,
  target,
  image,
  body,
  duration,
  topic,
  event_speakers = '',
  buttonText,
  height = '100%',
  gtmIsTrack = false,
  location = '',
  contentType = '',
}: Props) {
  const { t } = useTranslation();
  const splitTopics = topic.split(', ');
  const updatedTopic = splitTopics.length > 2 ? `${splitTopics.length} ${t('Topics')}` : topic;

  const handleClickCTA = () => {
    if (gtmIsTrack) {
      const dlEvent = {
        event: 'select_content',
        event_params: {
          cta_location: location,
          link_url: link || '',
          content_type: contentType,
          content_name: buttonText || '',
        },
      };
      dlEventSend(dlEvent);
    }
  };

  return (
    <Card
      as="div"
      className="d-flex col align-content-center gap-2 position-relative"
      style={{ height }}
    >
      <StyledImage>
        <GatsbyImage image={image} objectFit="fill" className="rounded-1" alt={title} />
      </StyledImage>
      <Card.Body className="d-flex flex-column justify-content-between">
        <div>
          <CardTopic>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: updatedTopic }} />
          </CardTopic>
          <CardTitleLink className="typography_h5 mb-2">{title}</CardTitleLink>
          <RichText className="fw-lighter typography_large" body={event_speakers} />
          <StyledParagraphTruncate>
            <RichText className="fw-lighter typography_large" body={body} />
          </StyledParagraphTruncate>
        </div>
        <StyledCardContainer>
          <ButtonLink
            variant="btn"
            to={link}
            target={target}
            cmsTheme={CMS_THEME.LIGHT}
            className="mt-3"
            onClick={() => handleClickCTA()}
          >
            {buttonText}
          </ButtonLink>
          <p className="typography_small text-muted mt-2">{duration}</p>
        </StyledCardContainer>
      </Card.Body>
    </Card>
  );
};

export default StyledCard;
