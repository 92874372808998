import { css, cx } from '@linaria/core';
import { StaticImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import Link from '../Link';

interface ParagraphProps extends Paragraph {
  link: LinkProps;
  relationships: {
    media?: {
      attributes: {
        alt?: string;
      };
      relationships: {
        logo: {
          localFile: {
            publicURL: string;
          };
          publicUrl: string;
        };
      };
    };
  };
}

interface ParagraphLinkedLogoProps {
  node: ParagraphProps;
}

// create new class to logo using cx.
const logoClass = css`
  .search-visible & {
    display: none;
  }
`;

const ParagraphLinkedLogo: React.FC<ParagraphLinkedLogoProps> = function ({ node }) {
  const { link } = node;
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];
  const wrapperClassNames = cx(logoClass, classes.join(' '));
  const media = node.relationships?.media;
  const logo = media?.relationships.logo?.publicUrl;
  const alt = media?.attributes?.alt || '';

  if (!logo) return null;

  return (
    <AnalyticsPoint
      type="module"
      typeLabel="site_navigation"
      label="site navigation"
      as="div"
      className={wrapperClassNames}
    >
      <AnalyticsPoint
        type="component"
        typeLabel="home_logo"
        label="Home logo"
        eventLabel="Home logo"
        action="logo click"
      >
        {link && (
          <Link to={link.url} {...link?.options?.attributes} aria-current={undefined}>
            <StaticImage
              src="../../assets/media/images/PI_Primary_logo.svg"
              alt={alt}
              className="logo"
              placeholder="none"
            />
            {/* <img src={logo} alt={alt} className="logo" /> */}
          </Link>
        )}
      </AnalyticsPoint>
    </AnalyticsPoint>
  );
};

export default ParagraphLinkedLogo;
