import { styled } from '@linaria/react';
import { Link, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import useAxiosPost from '../../hooks/useAxiosPost';

const StyledLink = styled(Link)`
  &:first-letter {
    text-transform: uppercase;
  }
`;

// markup
const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  useAxiosPost({
    url: '/api/log-404',
    payload: {
      path404: typeof window !== 'undefined' ? window.location.pathname : '',
    },
  });

  const langCode = location.pathname.split('/')[1];
  const linksList = [
    {
      key: 1,
      link: `/${langCode}/`,
      label: 'Startseite',
    },
    {
      key: 2,
      link: `/${langCode}/centresquare`,
      label: 'CentreSquare®',
    },
    {
      key: 3,
      link: `/${langCode}/about-us`,
      label: 'Über uns',
    },
  ];

  return (
    <Layout location={location} language={langCode as Languages}>
      <Seo
        title="Error 404: We’re soooo sorry! | Purina"
        meta={[{ name: 'description', content: "We didn't mean to lose your page." }]}
      />
      <Container className="p-5 text-center d-grid gap-5">
        <StaticImage
          src="../../assets/media/images/Purina-404.jpeg"
          alt="Dogs and cats looking over screen"
          height={600}
          width={1640}
        />
        <Stack className="gap-4">
          <h1 className="fw-normal">Entschuldigung!</h1>
          <h2 className="fw-light lh-base text-dark fs-x-large text-center">
            Die Seite, die Sie suchen, wurde verschoben oder existiert nicht mehr.
            <br />
            Versuchen Sie die folgenden Seiten:
          </h2>
        </Stack>
        <Stack
          direction="vertical"
          gap={3}
          className="justify-content-center align-items-center flex-sm-row"
        >
          {linksList.map(({ key, link, label }) => (
            <Button
              key={key}
              variant="outline"
              className="btn btn-outline-primary rounded-pill text-black"
              aria-label={label}
            >
              <StyledLink className="text-decoration-none text-reset" to={link}>
                {label}
              </StyledLink>
            </Button>
          ))}
        </Stack>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
