import { mdiFilePdfBox, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../../common/enums';
import { Button } from '../button/Button';
import ButtonDiadWrapper from '../button/ButtonDiadWrapper';
import RichText from '../rich_text/RichText';
import {
  BCS_LOSE_WEIGHT_THRESHOLD,
  BCS_LOSE_WEIGHT_THRESHOLD_CAT,
  BCS_MAINTAIN_WEIGHT_THRESHOLD,
  BCS_MAINTAIN_WEIGHT_THRESHOLD_CAT,
} from './FeedingCalculatorCalories';

type BcsRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export interface FormValues {
  weight: number;
  units: 'lb' | 'kg';
  age: string;
  bcs: BcsRange;
  pet_name: string;
  weight_goal: 'maintain' | 'lose';
}

interface AgeOption {
  value: string;
  label: string;
}
interface FeedingCalculatorFormProps {
  node: IStorageFeedingCalculator;
  updateWeightGoal: Function;
  submitCallback: Function;
}

// eslint-disable-next-line
const FeedingCalculatorForm = ({
  node,
  updateWeightGoal,
  submitCallback,
}: FeedingCalculatorFormProps) => {
  const storage = node;
  const cmsTheme = CMS_THEME.CREAM;

  const mediaPath = storage?.relationships?.media[0]?.relationships?.field_media_document?.uri?.url;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const bcsValue = watch('bcs', 5);

  const maintainOrGainWeightLabel =
    (bcsValue > 3 && storage.species === 'dog') || (bcsValue > 4 && storage.species === 'cat')
      ? storage.weight_goal.option_label_maintain
      : storage.weight_goal.option_label_gain;

  const displayMaintainWeightOption =
    (bcsValue < BCS_MAINTAIN_WEIGHT_THRESHOLD && storage.species === 'dog') ||
    (bcsValue < BCS_MAINTAIN_WEIGHT_THRESHOLD_CAT && storage.species === 'cat');

  const displayLoseWeightOption =
    (bcsValue > BCS_LOSE_WEIGHT_THRESHOLD && storage.species === 'dog') ||
    (bcsValue > BCS_LOSE_WEIGHT_THRESHOLD_CAT && storage.species === 'cat');

  const displayLink = bcsValue >= 7 && storage.species === 'dog';

  const displayLowBcsMessage = bcsValue < 3;

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'weight_goal') {
        updateWeightGoal(value.weight_goal);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, updateWeightGoal]);

  const { t } = useTranslation();

  let ageOptions: AgeOption[] = [];
  switch (storage.species) {
    case 'cat':
      ageOptions = [
        { value: 'adult', label: storage.age_group.option_cat_1 },
        {
          value: 'mature',
          label: storage.age_group.option_cat_2,
        },
        { value: 'senior', label: storage.age_group.option_cat_3 },
        { value: 'geriatric', label: storage.age_group.option_cat_4 },
      ];
      break;
    case 'dog':
      ageOptions = [
        { value: 'adult', label: storage.age_group.option_dog_1 },
        { value: 'medium', label: storage.age_group.option_dog_2 },
        { value: 'senior', label: storage.age_group.option_dog_3 },
      ];
      break;
    default:
      ageOptions = [];
      break;
  }

  return (
    <Form className="mb-3" onSubmit={handleSubmit(submitCallback)}>
      <Row className="gx-5 gy-3">
        <Col xs={12}>
          <Form.Group controlId="pet_name">
            <Form.Label>{storage.pets_name.label}</Form.Label>
            <Form.Control
              {...register('pet_name')}
              type="string"
              isInvalid={!!errors?.pet_name}
              aria-invalid={!!errors?.pet_name}
              aria-describedby="pet-name-description"
              placeholder={storage.pets_name.label}
            />
            {errors?.weight && (
              <Form.Control.Feedback type="invalid" role="alert" id="weight-description">
                {errors.weight.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group controlId="age" className="required">
            <Form.Label>{storage.age_group.label}</Form.Label>
            <Form.Select {...register('age')}>
              {ageOptions.map(option => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <RichText
            cmsTheme={cmsTheme}
            className="mt-1 ageDescription"
            body={storage?.age_group_description?.processed || ''}
          />
        </Col>

        <Col xs={8}>
          <Form.Group controlId="weight" className="required">
            <Form.Label>{storage.weight.label}</Form.Label>
            <Form.Control
              {...register('weight', {
                required: storage.weight.required_text,
                max: {
                  value: 1000,
                  message: 'The value in Weight has to be less than 1000.',
                },
              })}
              type="number"
              step=".1"
              max="1000"
              isInvalid={!!errors?.weight}
              aria-invalid={!!errors?.weight}
              aria-describedby="weight-description"
              placeholder={storage.weight.placeholder}
            />
            {errors?.weight && (
              <Form.Control.Feedback type="invalid" role="alert" id="weight-description">
                {errors.weight.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col xs={4}>
          <Form.Group controlId="units" className="required">
            <Form.Label>{storage.unit.label}</Form.Label>
            <Form.Select {...register('units')}>
              <option value="lb">{storage.unit.option_label_lbs}</option>
              <option value="kg">{storage.unit.option_label_kgs}</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group controlId="bcs" className="required">
            <Form.Label>{storage.bcs.label}</Form.Label>
            <Form.Select {...register('bcs')} defaultValue={5}>
              <option value={1}>1 - {t('Severely underweight')}</option>
              <option value={2}>2 - {t('Very thin')}</option>
              <option value={3}>3 - {t('Thin')}</option>
              <option value={4}>4 - {t('Slightly Underweight')}</option>
              <option value={5}>5 - {t('Ideal Weight')}</option>
              <option value={6}>6 - {t('Slightly Overweight')}</option>
              <option value={7}>7 - {t('Markedly Overweight')}</option>
              <option value={8}>8 - {t('Obese')}</option>
              <option value={9}>9 - {t('Clinically Obese')}</option>
            </Form.Select>
          </Form.Group>

          {displayLowBcsMessage && <p className="pt-3">{storage.bcs.underlying_condition_text}</p>}
        </Col>

        {mediaPath && (
          <Col xs={12}>
            <div className="pt-2 pb-3">
              <Icon path={mdiFilePdfBox} size={1.2} color="#e91c24" />
              <a href={mediaPath} className="h6 link ms-1" target="_blank" rel="noreferrer">
                {storage.bcs.media_download_label}
              </a>
            </div>
          </Col>
        )}

        {displayLink && (
          <Col xs={12}>
            <div className="pt-2 pb-3">
              <Icon path={mdiOpenInNew} size={1.2} color="#e91c24" />
              <a
                href={storage.highBcsLink.uri} // TODO: replace with field from Drupal, when available
                className="h6 link ms-1"
                target="_blank"
                rel="noreferrer"
              >
                {storage.highBcsLink.title}
              </a>
            </div>
          </Col>
        )}

        <Col xs={12}>
          <Form.Group controlId="weightGoal" className="required">
            <Form.Label>{storage.weight_goal.label}</Form.Label>
            <div className="d-flex flex-column gap-2">
              {displayLoseWeightOption && (
                <Form.Check
                  type="radio"
                  id="1"
                  value="lose"
                  {...register('weight_goal', {
                    required: storage.weight_goal.required_text,
                  })}
                  label={storage.weight_goal.option_label_lose}
                />
              )}
              {displayMaintainWeightOption && (
                <Form.Check
                  type="radio"
                  id="0"
                  value="maintain"
                  {...register('weight_goal', {
                    required: storage.weight_goal.required_text,
                  })}
                  label={maintainOrGainWeightLabel}
                />
              )}
            </div>
            {errors?.weight_goal && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.weight_goal.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <RichText
            cmsTheme={cmsTheme}
            className="mt-4 footnote"
            body={storage.widget_goal_description.processed}
          />
        </Col>

        <Col xs={12}>
          <ButtonDiadWrapper>
            <AnalyticsPoint type="component" as={Button} cmsTheme={cmsTheme}>
              {storage.button_label}
            </AnalyticsPoint>
          </ButtonDiadWrapper>
        </Col>
      </Row>
    </Form>
  );
};

export default FeedingCalculatorForm;
